import * as React from 'react';
import { graphql, Link, PageProps } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { CircleWavyCheck, Eye, Wrench, CheckCircle } from 'phosphor-react';

import Page from '../components/Page';
import Button from '../components/Button';
import Projects from '../components/Projects';
import Slideshow from '../components/Slideshow';
import Section from '../components/Section';
import Card from '../components/Card';
import { getCmsDocuments, getLayout } from '../cms';
import { useProjects } from '../hooks/data';
import * as styles from './index.module.css';
import type { HomeIntro } from '../types';

const COMPANY_FOUND_AT = new Date(1996, 11, 0);

const SERVICES = [
  'Plaatsing en herstelling van zuigerpompen en centrifugale pompen',
  'Plaatsing van besturingskasten',
  'Plaatsing en herstelling van diepzuigpompen',
  'Plaatsing en herstelling van vortexwaaier pompen',
  'Lassen en bewerken van HDPE buizen',
  'Revisie van centrifugale- en zuigerpompen',
];

const AMOUNT_OF_RANDOM_PROJECTS = 3;

const IndexPage: React.FC<PageProps> = ({ data }) => {
  const documents = getCmsDocuments(data);
  const projects = useProjects();
  const [{ title, html }] = getLayout(documents, '/home/intro/') as HomeIntro[];

  const services1 = SERVICES.slice(0, 3);
  const services2 = SERVICES.slice(3, SERVICES.length);

  const randomProjects = projects
    .sort(() => 0.5 - Math.random())
    .slice(0, AMOUNT_OF_RANDOM_PROJECTS);

  const projectImages = randomProjects.map(
    (project) => project.thumbnails[0].thumbnail
  );

  const yearsOfExperience =
    new Date().getUTCFullYear() - COMPANY_FOUND_AT.getFullYear();

  return (
    <Page title={title}>
      <Section className={styles.welcome}>
        <div>
          <Section.Title>{title}</Section.Title>
          <Section.Description dangerouslySetInnerHTML={{ __html: html }} />
        </div>
        <Slideshow>
          {projectImages.map((image) => (
            <GatsbyImage
              className={styles.image}
              key={image.base}
              alt={image.base}
              image={getImage(image.childImageSharp.thumb)!}
              objectFit="contain"
            />
          ))}
        </Slideshow>
      </Section>
      <Section>
        <Section.Title>Enkele realisaties</Section.Title>
        <Projects className={styles.projects} limit={3} />
        <Button
          className={styles.button}
          title="Bekijk alle realisaties"
          as={Link}
          to="/realisaties/"
        >
          Alle realisaties
        </Button>
      </Section>
      <Section>
        <Section.Title>Services</Section.Title>
        <Section.Description>
          Waar u ons kan voor inschakelen
        </Section.Description>
        <div className={styles.services}>
          <ul>
            {services1.map((service, index) => (
              <li key={index}>
                <CheckCircle
                  weight="fill"
                  className={styles.listIcon}
                  size={22}
                />
                {service}
              </li>
            ))}
          </ul>
          <ul>
            {services2.map((service, index) => (
              <li key={index}>
                <CheckCircle
                  weight="fill"
                  className={styles.listIcon}
                  size={22}
                />
                {service}
              </li>
            ))}
          </ul>
        </div>
      </Section>
      <Section>
        <Section.Title>Vakmanschap</Section.Title>
        <Section.Description>
          Werken met ons betekent duidelijke afspraken, een helder stappenplan
          en vlotte communicatie
        </Section.Description>
        <div className={styles.cards}>
          <Card
            title="Kwaliteit"
            body={`Met meer dan ${yearsOfExperience} jaar ervaring, wordt met professioneel advies een toepasselijke oplossing aangeboden.`}
          >
            <CircleWavyCheck size={22} weight="fill" className={styles.icon} />
          </Card>
          <Card
            title="Focus"
            body="Met oog voor detail wordt een kwalitatieve uitvoering afgeleverd."
          >
            <Eye size={22} weight="fill" className={styles.icon} />
          </Card>
          <Card
            title="Professionele producten"
            body="Bij de installaties wordt gebruik gemaakt van professionele materialen wat resulteert in degelijke uitvoeringen."
          >
            <Wrench size={22} weight="fill" className={styles.icon} />
          </Card>
        </div>
      </Section>
    </Page>
  );
};

export default IndexPage;

export const pageQuery = graphql`
  query homePage {
    allMarkdownRemark(
      filter: { fields: { filePath: { in: ["/home/intro/"] } } }
    ) {
      edges {
        node {
          html
          frontmatter {
            title
          }
          fields {
            filePath
          }
        }
      }
    }
  }
`;
