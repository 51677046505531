// extracted by mini-css-extract-plugin
export var welcome = "index-module--welcome--3xsUE";
export var description = "index-module--description--3QF5N";
export var image = "index-module--image--i2xlR";
export var projects = "index-module--projects--2XrN1";
export var button = "index-module--button--36ycx";
export var sectionTitle = "index-module--sectionTitle--JWdSu";
export var icon = "index-module--icon--L3mMm";
export var cards = "index-module--cards--2Hvfw";
export var services = "index-module--services--3aQHH";
export var listIcon = "index-module--listIcon--17Oow";