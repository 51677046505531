import * as React from 'react';
import clsx from 'clsx';
import { Carousel } from 'react-responsive-carousel';

import * as styles from './index.module.css';

const ANIMATION_DURATION_IN_MS = 4000;

type Props = {
  className?: string;
  children?: React.ReactNode;
};

const Slideshow = React.forwardRef<HTMLDivElement, Props>(
  ({ children, className }, ref) => {
    const classNames = clsx(className, styles.container);
    const [currentSlideIndex, setCurrentSlideIndex] = React.useState(0);

    const handleChange = (index: number) => {
      setCurrentSlideIndex(index);
    };

    return (
      <div ref={ref} className={classNames}>
        <Carousel
          showIndicators={false}
          showStatus={false}
          showArrows={false}
          selectedItem={currentSlideIndex}
          onChange={handleChange}
          stopOnHover={false}
          showThumbs={false}
          interval={ANIMATION_DURATION_IN_MS}
          swipeable={false}
          dynamicHeight={false}
          // FIXME: opening modal does focus on background
          autoFocus={false}
          useKeyboardArrows
          infiniteLoop
          autoPlay
        >
          {
            React.Children.map(children, (child, index) => (
              <React.Fragment key={index}>{child}</React.Fragment>
            )) as React.ComponentProps<typeof Carousel>['children']
          }
        </Carousel>
      </div>
    );
  }
);

export default Slideshow;
