import * as React from 'react';
import clsx from 'clsx';

import * as styles from './index.module.css';

type Props = {
  title: string;
  body: string;
  variant?: 'primary' | 'secondary';
};

const Card: React.FC<Props> = ({
  title,
  body,
  variant = 'primary',
  children,
}) => {
  const classNames = clsx(styles.card, {
    [styles.secondaryCard]: variant === 'secondary',
  });

  return (
    <article className={classNames}>
      {children}
      <h4 className={styles.title}>{title}</h4>
      <p className={styles.body}>{body}</p>
    </article>
  );
};

export default Card;
